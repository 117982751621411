import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/header/Header";
import Newsletters from "../components/layouts/Newsletters";
import Footer from "../components/footer/Footer";

const Contact = () => {
  const [data] = useState([
    {
      title: "Need Help? Contact With Our Hotline",
      icon: "fal fa-phone-volume",
      info: "+012 (345) 678 88",
      link: "tel:012345678",
    },
    {
      title: "Need Help? Contact With Our Hotline",
      icon: "fal fa-map-marker-alt",
      info: "55 Main Street, 2nd Block,3rd Floor, New York",
      link: "",
    },
    {
      title: "Need Help? Contact With Our Hotline",
      icon: "fal fa-envelope-open",
      info: "hotlineinfo@gmail.com www.bidzen.net",
      link: "mailto:abc@gmail.com",
    },
  ]);
  return (
    <div>
      <Header />
      <section className="fl-page-title">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-inner flex">
                <div className="page-title-heading">
                  <h2 className="heading">Contact</h2>
                </div>
                <div className="breadcrumbs">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>Contact</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="tf-contact tf-section">
        <div className="container">
          <div className="row">
            {data.map((item, index) => (
              <div key={index} className="col-md-4">
                <div className="sc-contact-infor">
                  <h4>{item.title}</h4>
                  <div className="icon">
                    <i className={item.icon}></i>
                  </div>
                  <div className="infor">
                    <Link to={item.link}>{item.info}</Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid">
          <div className="row">
            <iframe
              title="Bidzen"
              className="map-contact"
              src="https://drfeinberg-spectrogramsettings-formantfrequencies-u2k71q.streamlitapp.com"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            />
          </div>
        </div>
      </section>
      <section className="tf-section login-page contact-page pd-top-0">
        <div className="container">
          <div className="row jtf-content-center">
            <div className="col-md-8">
              <div className="form-create-item-content">
                <div className="form-create-item">
                  <div className="sc-heading">
                    <h3>Send Us Message</h3>
                    <p className="desc">
                      Most popular gaming digital nft market place{" "}
                    </p>
                  </div>
                  <form
                    id="create-item-1"
                    action="#"
                    method="GET"
                    acceptCharset="utf-8"
                  >
                    <input
                      type="text"
                      id="name"
                      className="tb-my-input"
                      name="name"
                      tabIndex="1"
                      placeholder="Your Full Name"
                      aria-required="true"
                      required
                    />
                    <input
                      type="email"
                      id="email"
                      className="tb-my-input"
                      name="email"
                      tabIndex="2"
                      placeholder="Email Address"
                      aria-required="true"
                      required
                    />
                    <select className="valid">
                      <option value="1">Subject</option>
                      <option value="2">Subject</option>
                      <option value="3">Subject</option>
                    </select>
                    <textarea
                      id="comment-message"
                      name="message"
                      tabIndex="4"
                      placeholder="Write Message"
                      aria-required="true"
                    ></textarea>
                    <button
                      name="submit"
                      type="submit"
                      id="comment-reply"
                      className="sc-button style letter style-2"
                    >
                      <span>Send Message</span>{" "}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Newsletters />
      <Footer />
    </div>
  );
};

export default Contact;
