import img1 from '../images/avatar/facial.png'
// import img2 from '../images/avatar/avt-2.jpg'
// import img3 from '../images/avatar/avt-3.jpg'
import img2 from '../images/avatar/Compare.png'
import img3 from '../images/avatar/Quality.png'
import img4 from '../images/avatar/Activity.png'
import img5 from '../images/avatar/Skin.png'
import img6 from '../images/avatar/IMG.png'


const dataBestSeller = [
    
    {
        img: img2,
        name: 'Face Compare',
        links:"https://face-compare-7b531caa.playground.demo.vamstack.com",
        price: 'Comparing the similarity of a person face',
        get: 'GET STARTED',
         styleclass: 'sc-button btn-bordered-white',
         

    },
    {
        img: img3,
        name: 'Face Quality',
        links:"https://face-quality-860e4807.playground.demo.vamstack.com",
        price: 'Finding the quality of the human face',
        get: 'GET STARTED',
         styleclass: 'sc-button btn-bordered-white',
         


    },
    {
        img: img5,
        name: 'Skin Detection',
        links:"https://skin-classification-37cc30ed.playground.demo.vamstack.com",
        price: 'Estimate the risk of acquiring skin diseases',
        get: 'GET STARTED',
         styleclass: 'sc-button btn-bordered-white',
        
        
    },
    {
        img: img6,
        name: 'Image Histrogram',
        links:"https://image-transformation-8fbf6d55.playground.demo.vamstack.com",
        price: 'Image processing to extract some useful information.',
        get: 'GET STARTED',
         styleclass: 'sc-button btn-bordered-white',
        
        
        
    },
    {
        img: img4,
        name: 'Activity Recognition',
        // links:"",
        price: 'Detection and recognition of human activity',
        get: 'COMING SOON',
         styleclass: 'btn-bordered-white coming-soon',

        
    },
    {
        img: img1,
        name: 'Face Search',
        price: 'Searching and Identifying person from face',
        // links: "",
        get: 'COMING SOON',
        styleclass: 'btn-bordered-white coming-soon',
         
    },
    // {
    //     img: img1,
    //     name: 'Jason M. Stalls',
    //     price: '523.7 ETH',
    // },
    // {
    //     img: img2,
    //     name: 'Frank F. Chan',
    //     price: '523.7 ETH',
    // },
    // {
    //     img: img3,
    //     name: 'Robert George',
    //     price: '523.7 ETH',
    // },
    //   {
    //     img: img1,
    //     name: 'Jason M. Stalls',
    //     price: '523.7 ETH',
    // },
    // {
    //     img: img2,
    //     name: 'Frank F. Chan',
    //     price: '523.7 ETH',
    // },
    // {
    //     img: img3,
    //     name: 'Robert George',
    //     price: '523.7 ETH',
    // }

]

export default dataBestSeller;