import img1 from '../images/post/post-1.jpg'
import img2 from '../images/post/post-2.jpg'
import img3 from '../images/post/post-3.jpg'
import img4 from '../images/post/post-4.jpg'
import img5 from '../images/post/post-5.jpg'
import img6 from '../images/post/post-6.jpg'

const dataBlog = [
    {
        img: img1,
        title: 'An Overview Of Most Common UX Design Deliverables',
        text : '',
        author: 'Senrew A. Sisson',
        time: '25 JAN 2022'
    },
    {
        img: img2,
        title: 'An Overview Of Most Common UX Design Deliverables',
        text : '',
        author: 'Senrew A. Sisson',
        time: '25 JAN 2022'
    },
    {
        img: img3,
        title: 'An Overview Of Most Common UX Design Deliverables',
        text : '',
        author: 'Senrew A. Sisson',
        time: '25 JAN 2022'
    },
    {
        img: img4,
        title: 'An Overview Of Most Common UX Design Deliverables',
        text : '',
        author: 'Senrew A. Sisson',
        time: '25 JAN 2022'
    },
    {
        img: img5,
        title: 'An Overview Of Most Common UX Design Deliverables',
        text : '',
        author: 'Senrew A. Sisson',
        time: '25 JAN 2022'
    },
    {
        img: img6,
        title: 'An Overview Of Most Common UX Design Deliverables',
        text : '',
        author: 'Senrew A. Sisson',
        time: '25 JAN 2022'
    },
]

export default dataBlog;