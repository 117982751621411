import img1 from '../images/product-item/image4.png'
// import img8 from '../images/product-item/image8.png'
import img3 from '../images/product-item/image5.png'
import img4 from '../images/product-item/image6.png'
import img7 from '../images/product-item/image7.png'
import img2 from '../images/product-item/industry.jpg'
import img5 from '../images/product-item/image22.png'
import img6 from '../images/vam/item33.jpg'




// import img2 from '../images/product-item/item-6.jpg'
// import img3 from '../images/product-item/item-10.jpg'
// import img4 from '../images/product-item/item-11.jpg'
// import img5 from '../images/product-item/item-7.jpg'
// import img6 from '../images/product-item/item-8.jpg'
// import img9 from '../images/product-item/item-9.jpg'
import imga1 from '../images/logo/logo.png'


const dataCollections = [
    {
        img: img7,    
        title: '‘’Hi-edge for Facial Analysis’’ ',
        tags: 'Hi-edge',
        imgAuthor: imga1,
        name:'VAMStack',
        styleclass: 'tf-col-item',
        col: 'col-md-4'
    },
    {
        img: img1,
        img1: img5,
        img2: img6,
        img3: img2,
        title: '‘’On-premise Server for Security and Surveillance ’’',
        tags: 'On-premise',
        imgAuthor: imga1,
        name:'VAMStack',
        styleclass: 'tf-col-itemx2',
        // wishlist: '152k',
        col: 'col-md-6'
    },
    {
        img: img3,
        title: '‘’On-premise Server for Facial Analysis ’’',
        tags: 'On-premise',
        imgAuthor: imga1,
        name:'VAMStack',
        styleclass: 'tf-col-item',
        col: 'col-md-4'
    },
    {
        img: img4,
        title: '‘’Hi-edge for Security and Surveillance’’',
        tags: 'Hi-edge',
        imgAuthor: imga1,
        name:'VAMStack',
        styleclass: 'tf-col-item',
        col: 'col-md-4'
    },
    // {
    //     img: img1,
    //     title: '‘’3D Space Rocket With Smoke Premium’’',
    //     tags: 'BSC',
    //     imgAuthor: imga1,
    //     name:'Daniel M.Bivens',
    //     styleclass: 'tf-col-item',
    //     col: 'col-md-3'
    // },
    // {
    //     img: img2,
    //     img1: img5,
    //     img2: img6,
    //     img3: img7,
    //     title: '‘’Multi-purpose 3D Space Rocket With Animate Real Special Smoke Premium Quality Gaming’’',
    //     tags: 'BSC',
    //     imgAuthor: imga2,
    //     name:'Daniel M.Bivens',
    //     styleclass: 'tf-col-itemx2',
    //     wishlist: '152k',
    //     col: 'col-md-6'
    // },
    // {
    //     img: img3,
    //     title: '‘’3D Space Rocket With Smoke Premium’’',
    //     tags: 'BSC',
    //     imgAuthor: imga3,
    //     name:'Daniel M.Bivens',
    //     styleclass: 'tf-col-item',
    //     col: 'col-md-3'
    // },
    // {
    //     img: img4,
    //     title: '‘’3D Space Rocket With Smoke Premium’’',
    //     tags: 'BSC',
    //     imgAuthor: imga4,
    //     name:'Daniel M.Bivens',
    //     styleclass: 'tf-col-item',
    //     col: 'col-md-3'
    // },

]

export default dataCollections;