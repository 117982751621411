import React from "react";

const BestSeller = (props) => {
  // const scrollToPlaygrounds = useRef(null);

  const data = props.data;
  return (
    <section className="tf-best-seller">
      <div className="best-seller-inner">
        <div className="row">
          <div className="col-md-12">
            <div className="sc-heading style-2">
              <div className="content-left">
                <div className="inner">
                  <h3>AI as a Service Playgrounds</h3>
                  <p className="desc">
                    AI Service Playgrounds powered by VAMStack Co.,Ltd.
                  </p>
                </div>
              </div>
              {/* <div className="content-right">
                <Link to="#" className="sc-button style-2">
                  <span>More</span>{" "}
                </Link>
              </div> */}
            </div>
          </div>
          {data.slice(0, 6).map((item, index) => (
            <div key={index} className="col-lg-2 col-md-4 col-6 ">
              <div className="sc-author">
                <div className="card-avatar">
                  <img src={item.img} alt="Bidzen" />
                </div>
                <div className="infor">
                  <h6> {item.name}</h6>
                  <div className="details">{item.price}</div>
                </div>
                <a
                  className={item.styleclass}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item.links}
                >
                  <span>{item.get}</span>
                </a>

                {/* <Link to={item.links} className="sc-button btn-bordered-white">
                  <span>GET STARTED</span>
                </Link> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BestSeller;
