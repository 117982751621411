import img1 from '../images/store/FM.png'
import img2 from '../images/store/FR.png'
import img3 from '../images/store/VM.png'
import img4 from '../images/store/PD.png'
import img5 from '../images/store/PCIO.png'
import img6 from '../images/store/VD.png'
import img7 from '../images/store/PCIR.png'
import img8 from '../images/store/PM.png'
import img9 from '../images/store/IDM.png'
import img10 from '../images/store/HU.png'
import img11 from '../images/store/FO.png'
import img12 from '../images/store/LAD.png'
import img13 from '../images/store/SDD.png'
import img14 from '../images/store/HD.png'




const dataPopularCollection = [
    {
        img: img8,
        title: 'VAM Personal Monitoring',
        tags: 'Security surveillance',

    },
    {
        img: img11,
        title: 'VAM Fall Out Detection',
        tags: 'Security surveillance',

    },
    {
        img: img14,
        title: 'VAM Helmet Detection',
        tags: 'Security surveillance',

    },
    
    {
        img: img2,
        title: 'VAM Face Recognition ',
        tags: 'Face Recognition,Security surveillance',
    },
    {
        img: img3,
        title: 'VAM Vehicle Monitoring',
        tags: 'Security surveillance',
    },
    {
        img: img4,
        title: 'VAM People Detection and Capture',
        tags: 'Security surveillance',
    },
    {
        img: img1,
        title: 'VAM Face Mask Detection and Capture',
        tags: 'Face Recognition',

    },
    {
        img: img5,
        title: 'VAM People Counting In-Out',
        tags: 'Security surveillance',

    },
    {
        img: img6,
        title: 'VAM Vehicle Detection and Capture',
        tags: 'Security surveillance',

    },
    {
        img: img7,
        title: 'VAM People Counting In ROI',
        tags: 'Security surveillance',

    },
    
    {
        img: img9,
        title: 'VAM Instrusion Detection and Monitoring',
        tags: 'Security surveillance',
    },
    {
        img: img10,
        title: 'VAM Hand-up for Help Detection',
        tags: 'Security surveillance',

    },
    
    {
        img: img12,
        title: 'VAM Living Activity Detection',
        tags: 'Security surveillance',

    },
    {
        img: img13,
        title: 'VAM Social Distancing Detection',
        tags: 'Security surveillance',

    },
    

]
export default dataPopularCollection;