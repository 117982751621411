
import img1 from '../images/store/vam.png'

const dataSlider = [
        {
        title: "VAMStack Platform",
        description: "Video Analytics Management (called VAM) is the powerful and flexible platform being suitable for various industry areas such as home/office and retails/shops etc.",
        img: img1,
    },
    // {
    //     title: "VAMStack",
    //     description: "Video Analytics Management (called VAM) is the powerful and flexible platform being suitable for various industry areas such as home/office and retails/shops etc.",
    //     img: img1,
    // },

    
]

export default dataSlider;