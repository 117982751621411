import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import img1 from "../../assets/images/post/post-recent-new-4.jpg";
// import img2 from "../../assets/images/post/post-recent-new-5.jpg";

import logo from "../../assets/images/logo/logo-vamdark.png";
import logo2x from "../../assets/images/logo/logo-vamdark.png";

const Footer = () => {
  const [dataSocial] = useState([
    {
      icon: "fab fa-facebook-f",
      link: "https://www.facebook.com/vamstack",
    },
    {
      icon: "fad fa-envelope",
    },
    {
      icon: "fab fa-linkedin-in",
    },
    {
      icon: "fab fa-youtube",
      link: "https://www.youtube.com/channel/UCff4Xps9v4kNUGph_ExCfnw/featured",
    },
  ]);

  // const [dataLinkMarket] = useState([
  //   {
  //     title:
  //       " VAMStack Headquarter 215, Pattanakarn 50, Suanluang, Suanluang Bangkok, 10250, Thailand",
  //     link: "",
  //   },
  //   {
  //     title:
  //       "VAMStack Bangsean Branch, 15/133 Village No.5, HuaiKapi, Chon Buri District, Chon Buri, Thailand 20130",
  //     link: "",
  //   },
  //   {
  //     title: "",
  //     link: "/item",
  //   },
  //   {
  //     title: "Social Network",
  //     link: "/item",
  //   },
  //   {
  //     title: "Domain Names",
  //     link: "/item",
  //   },
  //   {
  //     title: "Collectibles",
  //     link: "/item",
  //   },
  // ]);

  const [dataSupport] = useState([
    {
      title: "Setting & Privacy",
      link: "",
    },
    {
      title: "Help & Support",
      link: "",
    },
    // {
    //   title: "Live Auctions",
    //   link: "/item",
    // },
    // {
    //   title: "Item Details",
    //   link: "/item-details",
    // },
    {
      title: "24/7 Supports",
      link: "",
    },
    {
      title: "Blog",
      link: "",
    },
  ]);

  //   const [dataRecent] = useState([
  //     {
  //       img: img1,
  //       title: "Roll Out New Features Without Hurting Loyal Users",
  //       time: "25 JAN 2022",
  //     },
  //     {
  //       img: img2,
  //       title: "An Overview The Most Comon UX Design Deliverables",
  //       time: "25 JAN 2022",
  //     },
  //   ]);

  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);
  return (
    <div>
      <footer id="footer" className="clearfix bg-style">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4 col-12">
              <div className="widget widget-logo">
                <div className="logo-footer" id="logo-footer">
                  <Link to="/">
                    <img
                      id="logo_footer"
                      src={logo}
                      alt="nft-gaming"
                      srcSet={logo2x}
                    />
                  </Link>
                </div>
                <p className="sub-widget-logo">
                  Spin-off Design House from Faculty of Engineering and Faculty
                  of Informatics, Burapha University
                </p>
                <div className="widget-social">
                  <ul>
                    {dataSocial.map((item, index) => (
                      <li key={index}>
                        <Link to="">
                          <i className={item.icon}></i>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-8 col-12">
              <div className=" menu-marketplace">
                <h5 className="title-widget">Contact Us</h5>
                <p className="sub-widget-logo">
                  VAMStack Headquarter 215, Pattanakarn 50, Suanluang, Suanluang
                  Bangkok, 10250, Thailand.
                  <br></br> VAMStack Bangsean Branch, 15/133 Village No.5, Huai
                  Kapi, Chon Buri District, Chon Buri, Thailand 20130
                  <br />
                  E-mail: info@vamstack.com
                  <br />
                  Phone: +66(0)2-322-4976
                  {/* {dataLinkMarket.map((item, index) => (
                    <li key={index}>
                      <Link to={item.link}>{item.title}</Link>
                    </li>
                  ))} */}
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-12">
              <div className="widget widget-menu menu-supports">
                <h5 className="title-widget">Supports</h5>
                <ul>
                  {dataSupport.map((item, index) => (
                    <li key={index}>
                      <Link to={item.link}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget widget-post">
                <h5 className="title-widget">News & Post</h5>
                <ul className="post-new">
                  {dataRecent.map((item, index) => (
                    <li key={index}>
                      <div className="post-img">
                        <img src={item.img} alt="Post New" />
                      </div>
                      <div className="post-content">
                        <h6 className="title">
                          <Link to="/blog-details">{item.title}</Link>
                        </h6>
                        <Link to="/blog-details" className="post-date">
                          <i className="far fa-calendar-week"></i> {item.time}
                        </Link>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </footer>
      <div className="bottom">
        <div className="container">
          <div className="bottom-inner">
            Copyright ©2022 All rights reserved | VAMSTACK.CO.,LTD. Thailand
          </div>
        </div>
      </div>
      {isVisible && <Link onClick={scrollToTop} to="#" id="scroll-top"></Link>}
    </div>
  );
};

export default Footer;
